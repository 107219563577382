import { Controller, useFormContext } from "react-hook-form";
import { DialogContentText, FormControl, FormHelperText, Stack, TextField } from "@mui/material";

const CustomDomainForm = ({ config, project }) => {
    const form = useFormContext();
    const { errors } = form.formState;
    return (
        <Stack>
            <DialogContentText pb={2}> Для добавления домена Вам необходимо: </DialogContentText>
            <DialogContentText pb={1}>
                {`1) Создать в DNS A запись: ${config.ipv4} и TXT запись: ${project.ownerName}-${project.slug}`}
            </DialogContentText>
            <DialogContentText pb={1}>2) Дождаться, пока обновления распространятся по DNS серверам</DialogContentText>
            <DialogContentText pb={1}>3) Ввести доменное имя в поле ниже (без http или https)</DialogContentText>
            <FormControl>
                <Controller
                    rules={{
                        required: "Обязательное поле"
                    }}
                    name="domainName"
                    control={form.control}
                    render={({ field }) => <TextField {...field} fullWidth label="Доменное имя (без http или https)" />}
                />
            </FormControl>
            {errors.domainName && <FormHelperText error>{errors.domainName.message}</FormHelperText>}
        </Stack>
    );
};

export default CustomDomainForm;
