import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    domainName: "",
    activated: false,
    ingressType: "",
    ingressPorts: []
};

export const domainSlice = createSlice({
    name: "domain",
    initialState,
    reducers: {
        setDomain: (state, action) => {
            state.id = action.payload.id;
            state.domainName = action.payload.domainName;
            state.requireTLS = true;
        },
        resetDomain: () => initialState,
        setId: (state, action) => {
            state.id = action.payload;
            state.requireTLS = true;
        },
        setDomainName: (state, action) => {
            state.domainName = action.payload;
        }
    }
});

export const { setDomain, resetDomain, setId, setDomainName, setActivated } = domainSlice.actions;

export default domainSlice.reducer;
