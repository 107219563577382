import { useFormContext } from "react-hook-form";
import { useMemo } from "react";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import { useConfig } from "../../../../Configuration/hooks";

const AmveraDomainForm = ({ project }) => {
    const { amveraRegion, domainTcpSuffix, defaultDomainSuffix } = useConfig();
    const { slug, ownerName: username } = project;
    const form = useFormContext();

    const [ingressType] = form.watch(["ingressType"]);

    const domainName = useMemo(() => {
        if (!ingressType) return "";
        if (ingressType === "HTTP" || ingressType === "HTTPS") {
            return `${slug}-${username}.${defaultDomainSuffix}`;
        }
        return `${slug}-${username}.db-${amveraRegion}.${domainTcpSuffix}`;
    }, [ingressType, amveraRegion, username, slug, domainTcpSuffix, defaultDomainSuffix]);

    return (
        <Stack gap={2}>
            <FormControl>
                <TextField value={domainName} disabled fullWidth label="Доменное имя" />
                {!ingressType && <FormHelperText>Необходимо выбрать тип подключения.</FormHelperText>}
            </FormControl>
        </Stack>
    );
};

export default AmveraDomainForm;
