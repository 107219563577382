const prodConfig = {
    api: window.amvera.api_external_url,
    notificationsApi: window.amvera.notifications_external_url,
    gitBaseUri: window.amvera.git_external_url,
    ipv4: window.amvera.external_ip,
    profileLink: window.amvera.user_profile_link,
    defaultDomainSuffix: window.amvera.default_domain_suffix,
    domainTcpSuffix: window.amvera.default_tcp_suffix,
    webhookGitBaseUri: "https://webhook.git.amvera.ru",
    amveraRegion: window.amvera.region,
    oidc: {
        authority: window.amvera.auth_server_issuer,
        client_id: window.amvera.auth_server_client_id,
        redirect_uri: window.amvera.auth_redirect_url,
        post_logout_redirect_uri: window.amvera.auth_redirect_url
    }
};

export default prodConfig;
