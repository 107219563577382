import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useServices } from "../../../DI/hooks";
import CustomDomainForm from "./ui/custom-domain-form";
import AmveraDomainForm from "./ui/amvera-domain-form";

const ingressTypes = [
    {
        name: "HTTP",
        value: "HTTP"
    },
    {
        name: "HTTPS",
        value: "HTTPS"
    },
    {
        name: "POSTGRES",
        value: "POSTGRES"
    },
    {
        name: "MONGO",
        value: "MONGO"
    },
    {
        name: "REDIS",
        value: "REDIS"
    }
];

const DomainDialog = ({ open, onSave, onClose }) => {
    const form = useForm({
        mode: "onBlur",
        defaultValues: {
            domainName: null,
            ingressType: "",
            ingressPorts: [{ urlPath: "", port: null }]
        }
    });
    const fieldArray = useFieldArray({ control: form.control, name: "ingressPorts" });

    const { errors } = form.formState;
    const [ingressType] = form.watch(["ingressType"]);

    const [domainType, setDomainType] = useState("");

    const { config } = useServices();

    const currentProject = useSelector(state => state.currentProject);

    const { project } = currentProject;

    const fieldArrayLastIndex = useMemo(() => fieldArray.fields.length - 1, [fieldArray]);

    const handleChangeDomainType = useCallback(
        event => {
            setDomainType(event.target.value);
            form.resetField("domainName");
            form.resetField("ingressPorts");
        },
        [form]
    );

    const isNotDb = useMemo(() => !!(ingressType === "HTTP" || ingressType === "HTTPS"), [ingressType]);

    const handleOnClose = useCallback(() => {
        form.reset();
        setDomainType("");
        onClose();
    }, [onClose, form]);

    const handleAddNewPorts = useCallback(
        async data => {
            if (!data.ingressPorts[fieldArrayLastIndex]?.urlPath && !data.ingressPorts[fieldArrayLastIndex]?.port) {
                data.ingressPorts.pop();
            }
            onSave(data);
        },
        [onSave, fieldArrayLastIndex]
    );

    const handleIngressTypeChange = useCallback((event, onChange) => {
        const type = event.target.value;
        if (!(type === "HTTP" || type === "HTTPS")) {
            setDomainType("default");
        }
        onChange(event);
    }, []);

    const handleRemovePort = useCallback(index => fieldArray.remove(index), [fieldArray.remove]);

    const handleAddNewRow = useCallback(() => {
        fieldArray.append({ urlPath: "", port: null });
    }, [fieldArray.append]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Добавление доменного имени</DialogTitle>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleAddNewPorts)}>
                    <DialogContent dividers sx={{ px: 10 }}>
                        <Stack gap={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="ingress-type">Тип подключения</InputLabel>
                                <Controller
                                    rules={{
                                        required: "Обязательное поле"
                                    }}
                                    name="ingressType"
                                    control={form.control}
                                    render={({ field }) => (
                                        <Select
                                            label="Тип подключения"
                                            id="ingress-type"
                                            value={field.value}
                                            onChange={e => handleIngressTypeChange(e, field.onChange)}
                                        >
                                            {ingressTypes.map(({ name, value }) => (
                                                <MenuItem key={name} value={value}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.ingressType && (
                                    <FormHelperText error>{errors.ingressType.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="domain-type">Тип домена</InputLabel>
                                <Select
                                    label="Тип домена"
                                    id="domain-type"
                                    value={domainType}
                                    onChange={handleChangeDomainType}
                                >
                                    <MenuItem value="default">Бесплатный домен Амвера</MenuItem>
                                    <MenuItem value="custom" disabled={ingressType && !isNotDb}>
                                        Свой домен
                                    </MenuItem>
                                </Select>
                                {!domainType && <FormHelperText>Необходимо выбрать тип домена.</FormHelperText>}
                            </FormControl>
                            {domainType === "custom" && <CustomDomainForm config={config} project={project} />}
                            {domainType === "default" && (
                                <AmveraDomainForm
                                    project={project}
                                    fieldArrayLastIndex={fieldArrayLastIndex}
                                    fieldArray={fieldArray}
                                />
                            )}
                            {isNotDb && (
                                <div>
                                    <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography fontWeight={600} variant="body1">
                                                Дополнительные настройки
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack gap={2}>
                                                <Typography variant="body1">Порты:</Typography>
                                                <Stack gap={2} divider={<Divider orientation="horizontal" flexItem />}>
                                                    {fieldArray.fields.map((field, index) => (
                                                        <Grid container key={field.id} columns={{ xs: 4, sm: 12 }}>
                                                            <Grid item xs={3} sm={10} sx={{ display: "flex" }} gap={2}>
                                                                <Grid container columns={{ xs: 4, sm: 8 }} spacing={2}>
                                                                    <Grid item xs={4} sm={4}>
                                                                        <Controller
                                                                            rules={{
                                                                                pattern: {
                                                                                    value: /^\/.*/,
                                                                                    message:
                                                                                        "Путь должен начинаться с /."
                                                                                },
                                                                                required:
                                                                                    fieldArrayLastIndex !== index &&
                                                                                    "Укажите путь URL."
                                                                            }}
                                                                            control={form.control}
                                                                            name={`ingressPorts.${index}.urlPath`}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    variant="standard"
                                                                                    size="small"
                                                                                    placeholder="Введите URL-путь"
                                                                                    inputProps={{
                                                                                        sx: { fontSize: 14 }
                                                                                    }}
                                                                                    {...field}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errors.ingressPorts?.[index]?.urlPath && (
                                                                            <FormHelperText error>
                                                                                {
                                                                                    errors.ingressPorts?.[index]
                                                                                        ?.urlPath?.message
                                                                                }
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>

                                                                    <Grid item xs={4} sm={4}>
                                                                        <Controller
                                                                            rules={{
                                                                                valueAsNumber: true,
                                                                                required:
                                                                                    fieldArrayLastIndex !== index &&
                                                                                    "Укажите порт."
                                                                            }}
                                                                            control={form.control}
                                                                            render={({ field }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="number"
                                                                                    variant="standard"
                                                                                    size="small"
                                                                                    defaultValue={undefined}
                                                                                    placeholder="Введите порт"
                                                                                    onChange={e =>
                                                                                        field.onChange(
                                                                                            parseFloat(e.target.value)
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        sx: { fontSize: 14 }
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            name={`ingressPorts.${index}.port`}
                                                                        />

                                                                        {errors.ingressPorts?.[index]?.port && (
                                                                            <FormHelperText error>
                                                                                {
                                                                                    errors.ingressPorts?.[index]?.port
                                                                                        ?.message
                                                                                }
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={1}
                                                                sm={2}
                                                                sx={{ display: "flex" }}
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                {fieldArrayLastIndex !== index && (
                                                                    <IconButton onClick={() => handleRemovePort(index)}>
                                                                        <DeleteOutlineOutlinedIcon
                                                                            color="primary"
                                                                            fontSize="small"
                                                                        />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Stack>
                                                <Button onClick={handleAddNewRow}>Добавить</Button>
                                            </Stack>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleOnClose}>
                            Отмена
                        </Button>
                        <Button variant="contained" disabled={!domainType} type="submit">
                            Подтвердить и привязать
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default DomainDialog;
